import React from "react"

import SEO from "../components/seo"
import LayoutInfo from "../components/layout-info"
import img1 from "./../images/infoforesatte1.jpg"

import styles from "./unsubscribe.module.scss"

class UnsubscribePage extends React.Component {

    render() {

        return (
            <LayoutInfo>
                <SEO title="Avmelding feilet"

                    description="Avmelding av nyhetsbrev fra Leirskoledagboken feilet"
                />

                <div className="row">
                    <div className="col">
                        <h1 className="card-title">Leirskole - Ta vare på leirskolestemningen!</h1>
                        <div>
                            <img className={` ${styles.infoPicture} rounded float-left mr-3 mb-2`} src={img1} alt="Illustrasjonsfoto" />
                            <p>
                                Beklager men avmeldingen nyhetsbrevet til Leirskoledagboken feilet. Vennligst prøv igjen senere eller send en epost til<a
                                    href='mailto:kundeservice@leirskoledagboken.no'>kundeservice@leirskoledagboken.no</a> så hjelper vi
                                    deg.
                            </p>


                        </div>
                    </div>
                </div>
            </LayoutInfo>
        )
    }

}
export default UnsubscribePage
